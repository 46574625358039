@mixin provisionTablistStyle($bgColor, $tabColor) {
  .provision-box-card .nav-tabs {
    background-color: $bgColor !important;
    border-bottom: 0 !important;
    color: #fff !important;

    .nav-item.provision-tab {
      background-color: $tabColor !important;
    }
  }
}

@mixin provisionsColor {
  &.STRING {
    background-color: rgba(0, 0, 0, 0.9) !important;
    color: #fff !important;
    @include provisionTablistStyle(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.1));
  }

  &.DATE {
    background-color: rgba(255, 255, 255, 0.9) !important;
    color: #fff !important;
    @include provisionTablistStyle(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.1)
    );
  }

  &.DOUBLE {
    background-color: rgba(0, 215, 100, 0.9) !important;
    color: #fff !important;
    @include provisionTablistStyle(
      rgba(0, 215, 100, 0.9),
      rgba(0, 215, 100, 0.1)
    );
  }

  &.MONEY {
    background-color: rgba(0, 215, 100, 0.9) !important;
    color: #fff !important;
    @include provisionTablistStyle(
      rgba(0, 215, 100, 0.9),
      rgba(0, 215, 100, 0.1)
    );
  }

  &.TABLE {
    background-color: rgba(0, 215, 100, 0.9) !important;
    color: #fff !important;
    @include provisionTablistStyle(
      rgba(0, 215, 100, 0.9),
      rgba(0, 215, 100, 0.1)
    );
  }

  &.COMMON_NOUN {
    background-color: rgba(254, 207, 51, 0.9) !important;
    color: #fff !important;
    @include provisionTablistStyle(
      rgba(254, 207, 51, 0.9),
      rgba(254, 207, 51, 0.1)
    );
  }

  &.PROPER_NOUN {
    background-color: rgba(254, 207, 51, 0.9) !important;
    color: #fff !important;
    @include provisionTablistStyle(
        rgba(254, 207, 51, 0.9),
        rgba(254, 207, 51, 0.1)
      )
      // .provision-box-card .nav-tabs {
      //   background-color: rgba(254, 207, 51, 0.9) !important;
      //   border-bottom: 0 !important;
      //   color: #fff !important;
      //   .nav-item.provision-tab {
      //     background-color: rgba(254, 207, 51, 0.1) !important;
      //   }
      // };;;;;;;;;;;;;;;;;;;;
;
  }

  &.LOCAL_DATE {
    background-color: rgba(65, 155, 249, 0.9) !important;
    color: #fff !important;
    @include provisionTablistStyle(
      rgba(65, 155, 249, 0.9),
      rgba(65, 155, 249, 0.1)
    );
  }

  &.YEAR {
    background-color: rgba(65, 155, 249, 0.9) !important;
    color: #fff !important;
    @include provisionTablistStyle(
      rgba(65, 155, 249, 0.9),
      rgba(65, 155, 249, 0.1)
    );
  }

  &.ADDRESS {
    background-color: rgba(219, 124, 238, 0.9) !important;
    color: #fff !important;
    @include provisionTablistStyle(
      rgba(219, 124, 238, 0.9),
      rgba(219, 124, 238, 0.1)
    );
  }

  &.EMAIL {
    background-color: rgba(219, 124, 238, 0.9) !important;
    color: #fff !important;
    @include provisionTablistStyle(
      rgba(219, 124, 238, 0.9),
      rgba(219, 124, 238, 0.1)
    );
  }

  &.PHONE {
    background-color: rgba(219, 124, 238, 0.9) !important;
    color: #fff !important;
    @include provisionTablistStyle(
      rgba(219, 124, 238, 0.9),
      rgba(219, 124, 238, 0.1)
    );
  }

  &.MULTIPLE_CAT {
    background-color: rgba(235, 85, 58, 0.9) !important;
    color: #fff !important;
    @include provisionTablistStyle(
      rgba(235, 85, 58, 0.9),
      rgba(235, 85, 58, 0.1)
    );

    .nav-link {
      .auto-new-icon {
        color: #fff !important;
      }

      &.active,
      &:hover {
        .auto-new-icon {
          color: #f55753 !important;
        }
      }
    }
  }

  &.SINGLE_CAT {
    background-color: rgba(235, 85, 58, 0.9) !important;
    color: #fff !important;
    @include provisionTablistStyle(
      rgba(235, 85, 58, 0.9),
      rgba(235, 85, 58, 0.1)
    );

    .nav-link {
      .auto-new-icon {
        color: #fff !important;
      }

      &.active,
      &:hover {
        .auto-new-icon {
          color: #f55753 !important;
        }
      }
    }
  }
}

.provision-detail-container {
  @include provisionsColor;

  .provision-box-card .nav-tabs {
    @include provisionsColor;
  }

  li.nav-item.provision-tab {
    &:first-child {
      padding-left: 0 !important;
    }

    background-color: #1283ed;
    @include provisionsColor;
    margin-bottom: 0;

    label {
      color: #626262 !important;
    }

    a {
      font-weight: bold;
      color: #fff;
      border-bottom: 1px solid transparent;

      &:hover {
        border-color: #fff;
        border-bottom: 1px solid transparent;
        background: #fff;
        color: #7a8994;
      }

      &.active {
        padding-bottom: 10px;
        background-color: #fff;
        border-bottom-color: #fff;
        border: none;
        color: #7a8994;
        border-bottom: 5px solid #fff;
      }
    }
  }
}

.provision-box-card-header {
  background: #f6f9fa !important;
  color: #7a8994 !important;
  font-weight: bold !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;

  .card-title {
    font-weight: bold !important;
    font-size: 12px !important;
  }

  li {
    a {
      color: #fff !important;

      &.card-close,
      &.card-collapse {
        opacity: 1 !important;
        font-size: 15px !important;
      }
    }
  }

  @include provisionsColor;
}

.provision-box-card {
  border-radius: 0.5rem !important;
  border: none !important;
  margin-bottom: 1px !important;
  box-shadow: rgba(198, 203, 222, 0.45) 0 0.625rem 3.5rem 0 !important;

  .nav-tabs {
    border-color: transparent !important;
  }
}

.provision-box-card-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 0.5rem !important;

  tabset {
    scrollbar-width: thin;
    /* width */
    ::-webkit-scrollbar {
      width: 2px;
      transition: background-color 0.2s linear, height 0.2s ease-in-out;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.125);
      border-radius: 8px;
      transition: background-color 0.2s linear, height 0.2s ease-in-out;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.125);
      border-radius: 8px;
      transition: background-color 0.2s linear, height 0.2s ease-in-out;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.125);
      transition: background-color 0.2s linear, height 0.2s ease-in-out;
    }

    .nav-tabs {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
      overflow-x: auto !important;
      overflow-y: hidden !important;
      -webkit-overflow-scrolling: touch !important;

      & > li {
        float: none !important;
        -webkit-flex: 0 0 auto !important;
        flex: 0 0 auto !important;
      }
    }
  }

  .card {
    margin-bottom: 0;

    label {
      color: #626262 !important;
    }
  }

  .tab-container {
    .nav.nav-tabs {
      @include provisionsColor;
    }
  }
}

.provision-box-card {
  .streetAddress-formField {
    display: inline-block;
    width: 45%;
  }

  .suite-formField {
    display: inline-block;
    width: 15%;
    margin-left: 10px;
  }

  .country-formField {
    width: 30%;
    display: inline-block;
    margin-left: 10px;
  }

  .city-formField {
    width: 30%;
    display: inline-block;
    margin-left: 10px;
  }

  .postalCode-formField {
    width: 30%;
    display: inline-block;
  }

  .state-formField {
    width: 30%;
    display: inline-block;
    margin-left: 10px;
  }

  .commonNoun-formField {
    width: 100%;
  }

  .failed-parsed-formField {
    width: 100%;
  }

  .ng-select .ng-select-container {
    height: 35px !important;
    min-height: 35px !important;
    border-radius: 2px !important;
    border: solid 1px #dfe1e6;
    background-color: #f5f6f7;
  }

  .money-value-formField,
  .double-value-formField {
    width: 60%;
    display: inline-block;
  }

  .currency-formField,
  .unit-formField,
  .domain-formField {
    width: 24%;
    display: inline-block;
    margin-left: 10px;
    position: absolute;
  }

  .properNoun-formField {
    max-width: 300px;
  }

  .local-date-value-formField,
  .date-value-formField {
    max-width: 155px;
  }

  .single-cat-value-formField {
    max-width: 200px;
  }

  &.card {
    input.form-control,
    select {
      font-size: 11px !important;
      height: 30px !important;
      min-height: 30px !important;
    }

    textarea {
      font-size: 11px !important;
    }

    .form-group {
      margin-bottom: 5px !important;
    }

    span.formHelp {
      font-size: 12px !important;
    }
  }
}

.provision-review-form {
  box-shadow: none !important;
  padding-left: 0;
  padding-top: 6px;

  .streetAddress-formField {
    width: 100%;
  }

  .suite-formField {
    width: 100%;
    margin-left: 0;
  }

  .city-formField {
    width: 48%;
    margin-left: 0;
  }

  .postalCode-formField {
    width: 48%;
    margin-left: 10px;
  }

  .country-formField {
    width: 48%;
    margin-left: 10px;
  }

  .state-formField {
    width: 48%;
    margin-left: 0;
  }

  .money-value-formField,
  .double-value-formField {
    width: 100%;
    display: block;
  }

  .currency-formField,
  .unit-formField {
    margin-left: 0;
    width: 100%;
    position: static;
  }
}

.provision-review-form-menu-container {
  box-shadow: 0 0 6px 0 #c9ced5 !important;
  border-radius: 5px !important;

  .mat-mdc-menu-content {
    padding: 0 !important;
  }
}
