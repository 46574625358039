/* You can add global styles to this file, and also import other style files */
@import "./assets/css/preloading";
@import "./assets/css/provision-box-style";
@import "node_modules/pretty-checkbox/src/pretty-checkbox";
@import "node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "node_modules/ngx-ui-switch/ui-switch.component";
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import "./assets/css/individual-abstract";
@import "./assets/fonts/pages-icon/icons";
@import "./assets/css/calculators";
@import "./assets/css/material-override";
//@import "./assets/fonts/Muli/muli-font";
@import "./assets/fonts/roboto/roboto-font";
@import "node_modules/@swimlane/ngx-datatable/index";
@import "node_modules/@swimlane/ngx-datatable/themes/material";
@import "node_modules/@swimlane/ngx-datatable/assets/icons";
@import "node_modules/@angular/material";

//*:not(i):not(.pg-select-arrow):not(.pg-select-arrow:before) {
//  font-family: "Muli" !important;
//}

* {
  outline: none !important;
}

$blue-color: #1283ed;
$white-color: #ffffff;
$dark-grey: #7a8994;

@mixin iconSize($size) {
  width: $size;
  height: $size;
  min-height: $size;
  min-width: $size;
  line-height: $size;
  font-size: $size;
}

@mixin iconSizeNoLineHeight($size) {
  width: $size;
  height: $size;
  min-height: $size;
  min-width: $size;
  font-size: $size;
}

.basic-text {
  font-size: 16px;
  line-height: 19px;
  color: $dark-grey;
  //font-family: Roboto;
}

.popover {
  z-index: 1 !important;
}

.popover-header {
  margin-top: 0;
}

.text-center {
  text-align: center !important;
}

datatable-body-cell {
  cursor: pointer !important;
}

.editable-column {
  cursor: text !important;
}

.none-editable-column {
  color: rgba(44, 44, 44, 0.5) !important;
  cursor: not-allowed !important;
}

span.formHelp {
  margin-bottom: 10px !important;
  display: inline-block;
  color: #344563 !important;
  font-size: 11px;
}

sf-form-element-action {
  button {
    background: #2e6da4 !important;
    color: #fff !important;
    border-color: #2e6da4 !important;
    margin-right: 10px;
  }
}

.has-error .form-control {
  border-color: #ccc !important;
}

bs-dropdown-container {
  z-index: 9999;
}

@media (min-width: 768px) {
  .modal .modal-dialog.provision-table {
    width: 1000px !important;
    max-width: unset !important;
  }
}

.dynamic-table-provision {
  .datatable-body .datatable-body-row .datatable-body-cell {
    font-size: 12px !important;
  }

  .datatable-header .datatable-header-cell {
    padding: 0 !important;
  }

  .datatable-header
    .datatable-header-cell
    .datatable-header-cell-template-wrap {
    margin-top: -2px;

    button#button-split {
      padding: 4px 12px;
    }
  }

  .datatable-body .datatable-body-row .datatable-body-cell,
  .datatable-header
    .datatable-header-cell
    .datatable-header-cell-template-wrap {
    padding: 5px !important;

    button#buttons-split {
      padding: 3px 13px !important;
    }
  }
}

.card.card-default {
  border: 1px solid rgba(0, 0, 0, 0.07);
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  color: rgba(98, 98, 98, 1) !important;
  cursor: not-allowed;
}

typeahead-container {
  width: 100%;
}

#web-task-list,
.individual-abstract-history {
  .empty-row {
    padding: 1rem !important;
  }
}

.ngx-datatable .datatable-header .datatable-header-cell .resize-handle {
  border-right: 1px solid #ededed !important;
}

.add-new-tab {
  a.nav-link {
    padding: 5px 0 3px 0 !important;

    button {
      color: #fff !important;
    }

    &:hover {
      border-bottom: 4px solid #fff !important;
      color: #7a8994 !important;

      button {
        color: #7a8994 !important;
      }
    }

    &.active {
      border-bottom: 5px solid #fff !important;
      color: #7a8994 !important;

      button {
        color: #7a8994 !important;
      }
    }
  }
}

.dynamic-table-provision {
  .datatable-header .datatable-header-cell {
    max-height: 70px !important;
    line-height: 1 !important;
  }
}

.ngx-datatable {
  overflow-y: visible !important;
}

.TABLE {
  .tab-content {
    .ps {
      overflow-y: scroll !important;

      .ps__thumb-y {
        background-color: transparent !important;
      }
    }
  }
}

ngx-spinner {
  .overlay,
  .black-overlay {
    left: unset !important;
    top: 14px !important;
    right: 29px !important;
    width: 35px !important;
    height: 35px !important;
    z-index: 99999 !important;
    border-radius: 49px !important;
  }
}

.ng-resizable-handle {
  &.ng-resizable-n,
  &.ng-resizable-e,
  &.ng-resizable-s,
  &.ng-resizable-w,
  &.ng-resizable-ne,
  &.ng-resizable-ns,
  &.ng-resizable-nw {
    width: 15px !important;
  }
}

.dropdown-menu-right {
  top: 17px !important;
}

/* width */
//::-webkit-scrollbar {
//  width: 10px;
//  transition: background-color .2s linear, height .2s ease-in-out;
//}
//
//__<<ngM2ThemingMigrationEscapedComment2>>__
//::-webkit-scrollbar-track {
//  box-shadow: inset 0 0 2px grey;
//  border-radius: 5px;
//  transition: background-color .2s linear, height .2s ease-in-out;
//}
//
//__<<ngM2ThemingMigrationEscapedComment3>>__
//::-webkit-scrollbar-thumb {
//  background: #aaa;
//  border-radius: 10px;
//  transition: background-color .2s linear, height .2s ease-in-out;
//}
//
//__<<ngM2ThemingMigrationEscapedComment4>>__
//::-webkit-scrollbar-thumb:hover {
//  background: #626262;
//  transition: background-color .2s linear, height .2s ease-in-out;
//}

itls-input-dropdown-component {
  .ng-select-container {
    border-radius: 0 5px 5px 0 !important;
    min-height: 35px !important;
    height: 35px !important;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 12px !important;
  color: #344563 !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  font-size: 12px !important;
}

.ng-select .ng-clear-wrapper {
  width: 8px !important;
}

.ng-select .ng-clear-wrapper .ng-clear {
  font-size: 14px !important;
}

il-rent-schedule-calculator-modal {
  .nav-tabs ~ .tab-content {
    padding: 0;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #f5f6fa;
    border-color: #dee2e6 #dee2e6 #f5f6fa;
    font-weight: bold;

    &:active {
      background-color: #f5f6fa !important;
    }
  }

  .nav-tabs > li > a.active:hover,
  .nav-tabs > li > a.active:focus {
    background-color: #f5f6fa !important;
    border-bottom-color: #f5f6fa !important;
  }
}

itls-input-dropdown-component {
  &.active-input-dropdown {
    .input-dropdown-container {
      border: 1px solid #10cfbd !important;
      border-radius: 6px !important;
    }
  }
}

.form-provisions-list {
  background: rgb(246, 249, 250);
  padding-top: 15px !important;
}

.form-provision-card {
  .card-header {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-height: 30px !important;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

button {
  .btn-spinner {
    display: none;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: 0.1em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
  }
}

button.is-loading {
  .btn-spinner {
    margin-right: 2px;
    display: inline-block;
    margin-left: 2px;
  }
}

il-web-abstraction-page-sidebar itls-pgcard .card.card-default {
  border-radius: 5px !important;

  .card-header {
    padding-top: 16px !important;
  }
}

.card .card-header .card-title,
.nav-tabs > li > a {
  font-family: Roboto, serif !important;
}

.snippet-list {
  .card.card-default {
    margin-bottom: 12px !important;
    border-radius: 5px !important;

    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1) !important;
    }
  }

  .active-snippet {
    border: 1px solid #22ded7 !important;
  }

  a.nav-link:hover {
    border-bottom-width: 4px !important;
  }

  .card-controls {
    ul {
      li {
        position: absolute !important;
        bottom: 11px !important;
        font-size: 19px !important;
        right: 6px !important;
        display: none !important;
      }
    }
  }
}

.form-group label:not(.error) {
  font-family: Roboto !important;
}

.new-window-provision-box {
  .provision-box-card {
    border-radius: 0 !important;
  }
}

il-web-abstraction-snippets-tab {
  .card-title {
    width: 100%;
  }
}

.card.card-default {
  border-radius: 5px !important;

  &:not(.no-border):hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1) !important;
  }
}

il-web-abstraction-page-provision-box-window {
  .provision-detail-container {
    width: unset !important;
    min-width: 365px !important;
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 2px !important;
    clear: both !important;
    margin: 0 auto !important;
    float: unset !important;
    position: static !important;

    .provision-box-card {
      border-radius: 0 !important;
    }
  }
}

il-web-abstraction-page-provision-box {
  .card-progress {
    background-color: rgba(255, 255, 255, 0.4) !important;
  }
}

.multi-line-tooltip {
  white-space: pre-line;
  max-width: none;
}

.view-source-modal {
  .modal-dialog.modal-lg {
    width: 800px;

    .modal-body {
      padding: 12px !important;
    }
  }
}

.modal-lg {
  width: 1024px !important;
  max-width: unset !important;
}

// added by Material design
html,
body {
  height: 100%;
}

//body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

ng-dropdown-panel.ng-dropdown-panel.document-filename
  .ng-dropdown-panel-items
  .ng-option {
  white-space: normal;
}

//il-web-abstraction-page-provision-box {
//  .widget {
//    position: relative !important;
//
//    &:after {
//      content: "" !important;
//      border-top: 15px solid #007bff !important;
//      border-left: 15px solid transparent !important;
//      border-right: 15px solid transparent !important;
//      -webkit-transform: rotate(-45deg) !important;
//      z-index: 1 !important;
//      opacity: 0.5 !important;
//      position: absolute !important;
//      right: -9px !important;
//      bottom: -1px !important;
//      pointer-events: none !important;
//      background-color: #fff !important;
//    }
//
//    textarea {
//      position: relative !important;
//      z-index: 1 !important;
//    }
//  }
//}

il-ui-data-table,
intelease-report-data-table {
  .ngx-datatable .datatable-footer .datatable-pager .datatable-icon-left:before,
  .ngx-datatable .datatable-footer .datatable-pager .datatable-icon-skip:before,
  .ngx-datatable
    .datatable-footer
    .datatable-pager
    .datatable-icon-right:before,
  .ngx-datatable
    .datatable-footer
    .datatable-pager
    .datatable-icon-prev:before {
    top: 0 !important;
  }

  .ngx-datatable.material
    datatable-body-row.datatable-body-row.datatable-row-even {
    background: #fafbfc;
  }

  .ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 0 !important;
    vertical-align: middle !important;
    font-size: 12px !important;
    line-height: 1 !important;
  }

  .ngx-datatable.material
    .datatable-body
    .datatable-body-row
    .datatable-body-cell {
    color: #344563 !important;
  }

  .ngx-datatable.material .datatable-body .datatable-body-row a {
    color: #344563;
  }

  .ngx-datatable.fixed-header
    .datatable-header
    .datatable-header-inner
    .datatable-header-cell {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    max-height: 54px !important;
    font-family: Roboto;
    font-size: 12px;
    border-bottom: unset !important;
  }

  .ngx-datatable.material.fixed-header
    .datatable-header
    .datatable-header-inner
    .datatable-header-cell {
    color: #505f79;
  }

  .ngx-datatable.dark {
    .datatable-header .datatable-header-cell {
      color: white;
    }

    .datatable-header .datatable-header-cell.sortable .sort-btn {
      color: unset;
    }
  }

  .ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell {
    background-color: unset !important;
  }

  .ngx-datatable .datatable-body .datatable-body-row {
    border-top: unset !important;
  }
}

.il-ui-data-table {
  .ngx-datatable.material.single-selection .datatable-body-row.active,
  .ngx-datatable.material.single-selection
    .datatable-body-row.active
    .datatable-row-group,
  .ngx-datatable.material.multi-selection .datatable-body-row.active,
  .ngx-datatable.material.multi-selection
    .datatable-body-row.active
    .datatable-row-group,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active,
  .ngx-datatable.material.multi-click-selection
    .datatable-body-row.active
    .datatable-row-group {
    background-color: #fef6dd !important;
  }

  .ngx-datatable.material .datatable-body .datatable-body-row.active {
    background-color: #fef6dd !important;
  }

  .ngx-datatable.dark .datatable-body .datatable-body-row.active {
    background-color: #fef6dd !important;
    color: #091e42;
  }
}

il-folder,
intelease-folder-v2,
il-drive-search-results,
intelease-drive-search-results {
  .ngx-datatable {
    margin-bottom: 0 !important;
  }

  .ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell {
    text-align: left;
    vertical-align: middle;
    padding: 0;
    font-size: 12px;
    background-color: unset !important;

    .datatable-body-cell-label {
      .row-label {
        padding: 10.5px 20px 10.5px 16px;
        font-size: 12px;
      }
    }
  }

  .ngx-datatable.material
    .datatable-body
    .datatable-body-row
    .datatable-body-cell {
    .datatable-body-cell-label {
      .row-label {
        color: #344563;
      }
    }
  }

  .ngx-datatable.dark .datatable-body .datatable-body-row .datatable-body-cell {
    .datatable-body-cell-label {
      .row-label {
        color: #fff;
      }
    }
  }

  .datatable-header {
    border-bottom: 1px solid #dfe1e6;
  }

  .ngx-datatable.material
    datatable-body-row.datatable-body-row.datatable-row-even {
    background: #fafbfc;
  }

  //.ngx-datatable.dark datatable-body-row.datatable-body-row.datatable-row-even {
  //  color: #091e42;
  //}

  .ngx-datatable.material .datatable-body .datatable-body-row a {
    color: #344563;
  }

  .ngx-datatable.fixed-header
    .datatable-header
    .datatable-header-inner
    .datatable-header-cell {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    max-height: 54px !important;
    font-family: Roboto;
    font-size: 12px;
    border-bottom: unset !important;
    padding-left: 16px !important;
  }

  .ngx-datatable.fixed-header.material
    .datatable-header
    .datatable-header-inner
    .datatable-header-cell {
    color: #505f79;
  }

  .ngx-datatable .datatable-body .datatable-body-row {
    border-top: unset !important;
  }

  .ngx-datatable.dark {
    .datatable-header .datatable-header-cell {
      color: white;
    }

    .datatable-header .datatable-header-cell.sortable .sort-btn {
      color: unset;
    }
  }

  .ngx-datatable.dark .datatable-body .datatable-body-row.active {
    background-color: #fef6dd !important;
    color: #091e42;
  }

  .ngx-datatable.dark
    .datatable-body
    .datatable-body-row.active
    .datatable-body-cell {
    .datatable-body-cell-label {
      .row-label {
        color: #091e42;
      }
    }
  }

  .ngx-datatable.table-hover
    .datatable-body
    .datatable-body-row:hover
    .datatable-body-cell {
    background-color: #eee !important;
    transition-property: background !important;
    transition-duration: 0.3s !important;
    transition-timing-function: linear !important;
  }

  .ngx-datatable .datatable-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54);
  }
}

.calendar-sidenav {
  .main-body {
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
    mat-list {
      /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
      /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
      mat-list-item {
        /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
        .mat-mdc-list-item-content {
          padding: 0 !important;
        }
      }
    }
  }
}

.form-control {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.ngx-datatable .empty-row {
  padding-top: 10px;
  margin-left: 10px;
}

.ngx-datatable.material {
  box-shadow: none !important;
}

.ngx-datatable.material .datatable-footer .datatable-pager a {
  color: #000 !important;
}

.il-abstract-filter {
  .mat-mdc-optgroup-label {
    display: none !important;
  }
}

itls-vertical-sidebar-layout {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}

itls-pages {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}

itls-app {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  min-width: 0;
}

itls-auth-component {
  width: 100%;
}

intelease-calendar,
il-ui-calendar {
  .tui-full-calendar-weekday-schedule-title {
    font-size: 10px !important;
  }

  .tui-full-calendar-popup-detail .tui-full-calendar-schedule-title {
    font-size: 12px !important;
  }

  .tui-full-calendar-popup-container {
    padding: 10px !important;
    text-align: left !important;
  }

  .tui-full-calendar-weekday-grid-line
    .tui-full-calendar-weekday-grid-more-schedules {
    color: #101010 !important;
  }

  .tui-full-calendar-popup {
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2) !important;
  }

  .tui-full-calendar-weekday-schedule {
    box-shadow: 0 0 8px 0 rgba(223, 225, 230, 0.8) !important;
  }

  .tui-full-calendar-month-dayname {
    background-color: #f5f6f7 !important;
  }
}

a[target="_blank"]::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 5px;
}

.mat-mdc-tooltip:empty {
  visibility: hidden;
}

.notification-menu {
  min-width: 539px !important;
  min-height: 496px !important;
  left: 248px;
  position: absolute;
  top: -46px;
  box-shadow: 0 0 20px 0 #c9ced5 !important;
  border-radius: 2px !important;

  .mat-mdc-menu-content {
    padding: 0 !important;
  }
}

.kanban-card-detail {
  .attachment-list-item {
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
    .mat-mdc-list-item-content {
      padding: 0 !important;
    }
  }
}

.share-kanban-issue {
  .mat-mdc-menu-content {
    padding: 0 !important;
    overflow: hidden !important;
  }
}

.sidenav-notification-icon {
  .mat-mdc-badge-content {
    top: -13px !important;
    right: -12px !important;
  }
}

.itls-filter,
.filter-address-widget {
  .mat-mdc-form-field {
    margin: 0 4px -19px 4px !important;
    min-width: 104px;
    max-width: 104px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-mdc-form-field-label-wrapper {
    //top: -1px !important;

    label {
      top: 2.425em !important;
      font-size: 12px !important;

      &.mat-empty.mat-mdc-form-field-empty {
        top: 1.825em !important;
      }
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-outline .mat-mdc-form-field-text-infix {
    padding: 12px 0 7px !important;
    border-top: 0 !important;
    font-size: 10px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-outline .mat-mdc-form-field-flex {
    padding: 0 0.4em 0 0.4em !important;
  }
}

.filter-address-widget {
  min-width: 170px !important;
  padding-right: 8px !important;

  .mat-mdc-menu-item {
    padding: 0 4px !important;
    margin: 2px 0 !important;
  }

  mat-form-field {
    width: 100% !important;
    max-width: 100% !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-mdc-form-field-label-wrapper {
    top: -0.1em !important;
    font-size: 12px !important;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-floating-label {
      top: 1.925em !important;
      margin-top: -0.48em !important;
      font-size: 10px !important;
    }
  }

  .form-control {
    min-height: 30px !important;
    height: 30px !important;
  }

  .form-group {
    margin-bottom: 5px !important;
  }

  span.formHelp {
    margin-bottom: 0 !important;
  }

  .city-formField {
    width: 48%;
    float: left;
  }

  .postalCode-formField {
    width: 48%;
    float: right;
  }

  .state-formField {
    width: 48%;
    float: left;
  }

  .country-formField {
    width: 48%;
    float: right;
  }
}

.filter-address-fields-widget {
  padding-right: 0 !important;
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  .mat-mdc-list-base .mat-mdc-list-option {
    height: 28px !important;
  }
}

.textarea-widget {
  max-height: 300px;
}

.itls-filter-menu {
  .mat-mdc-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.filter-select {
  .mat-mdc-select-panel .mat-mdc-option {
    line-height: 2.5em !important;
    height: 2.5em !important;
  }
}

.calendar-detail {
  .mat-drawer-content {
    overflow: unset !important;
  }

  .tui-view-6 {
    background: #f5f6f7;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-mdc-checkbox-layout {
    margin-bottom: 0;
  }
}

.report-chart-setting {
  min-width: 300px !important;
  min-height: 240px !important;
}

.calendar-filter-menu,
.report-filter-menu {
  min-width: 506px !important;
  min-height: 446px !important;

  .mat-mdc-menu-content {
    height: 100%;
  }

  .filter-footer {
    box-shadow: 0 6px 6px 0 #c9ced5;
    position: absolute;
    bottom: -8px;
    width: 100%;
    text-align: right;
    padding: 10px;
    background: #fff;
    z-index: 1;
    border-top: 1px solid #ebebeb;
  }
}

.report-fields-menu {
  min-width: 284px !important;
  min-height: 250px !important;
}

.controls-row.presentation {
  display: none !important;
}

.settings-menu {
  .mat-mdc-menu-item-submenu-trigger::after {
    display: none !important;
  }
}

//[class^='datatable-icon-']:before, [class*='datatable-icon-']:before {
//  font-family: pages-icon !important;
//}

.toc-dropdown {
  min-width: 320px !important;
  overflow-x: hidden !important;
}

.hidden {
  display: none !important;
}

.hidden-with-space {
  visibility: hidden;
}

.top {
  z-index: 999 !important;
}

.abstract-summary-modal-panel {
  .mdc-dialog__container {
    overflow: visible;
    background: #fff;
    min-width: 850px !important;
    min-height: 350px !important;
    padding: 0 !important;
    position: relative;
    max-width: 850px;
  }
}

.agenda-detail-container-modal {
  .mdc-dialog__container {
    overflow: visible;
    background: #fff;
    border-radius: 2px;
    padding: 0 !important;
    position: relative;

    .mat-mdc-dialog-content {
      margin: 0;
      padding: 0;
    }
  }
}

.uploading-box-panel {
  .mdc-dialog__container {
    overflow: visible;
    background: #fff;
    min-width: 320px !important;
    min-height: 44px !important;
    z-index: 1 !important;
    padding: 0 !important;
    box-shadow: 0 0 20px 0 #c9ced5 !important;
    border-radius: 3px 3px 0 0 !important;
    position: relative;
    max-width: 320px;
  }

  div.widget.form-group {
    margin-bottom: 4px;
  }

  span.formHelp {
    margin-bottom: 0 !important;
  }

  .form-control {
    min-height: 20px !important;
    border-radius: 2px !important;
    border: solid 1px #dfe1e6;
    background-color: #f5f6f7;
    font-size: 12px;
  }

  .ng-resizable-handle.ng-resizable-se {
    z-index: 1;
  }
}

.provision-box-modal-panel {
  .mdc-dialog__container {
    overflow: visible;
    background: #fff;
    min-width: 500px !important;
    min-height: 44px !important;
    z-index: 1 !important;
    padding: 0 !important;
    box-shadow: 0 0 20px 0 #c9ced5 !important;
    border-radius: 2px !important;
    position: relative;
    max-width: 824px;
  }

  div.widget.form-group {
    margin-bottom: 4px;
  }

  span.formHelp {
    margin-bottom: 0 !important;
  }

  .form-control {
    min-height: 20px !important;
    border-radius: 2px !important;
    border: solid 1px #dfe1e6;
    background-color: #f5f6f7;
    font-size: 12px;
  }

  .ng-resizable-handle.ng-resizable-se {
    z-index: 1;
  }
}

.html-value {
  white-space: pre-wrap !important;
}

il-filter-widget-chooser {
  .mat-mdc-form-field:not(.mat-mdc-form-field-appearance-legacy)
    .mat-mdc-form-field-text-prefix
    .mat-datepicker-toggle-default-icon,
  .mat-mdc-form-field:not(.mat-mdc-form-field-appearance-legacy)
    .mat-mdc-form-field-text-suffix
    .mat-datepicker-toggle-default-icon {
    width: 1em !important;
    height: 1em !important;
  }
}

.mat-mdc-button-no-hover-effect.mat-mdc-button .mat-mdc-focus-indicator {
  background-color: transparent;
}

.no-padding-modal-panel {
  .mdc-dialog__container {
    padding: 0;
  }
}

.no-user-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                     supported by Chrome, Edge, Opera and Firefox */
}

.document-diff-tool {
  .mdc-dialog__container {
    padding: 0;
    overflow: hidden !important;
  }
}

.toolbar {
  position: absolute !important;
  top: 8px !important;
  right: 20px !important;
  z-index: 100 !important;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    line-height: 30px !important;
    padding: 0 8px !important;

    &.more-btn {
      border-radius: 2px !important;
      background-color: #f5f6f7 !important;
      height: 24px !important;
      line-height: 1 !important;

      mat-icon {
        margin-top: -4px !important;
        margin-right: 0 !important;
        line-height: 14px !important;
      }
    }
  }

  mat-icon {
    @include iconSize(14px);
  }
}
.mat-icon {
  position: relative;
  svg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}
.mat-icon {
  position: relative;
  svg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}

.intelease-web-notifications {
  .comment-text {
    strong {
      a {
        color: #1a73e8 !important;
      }
    }
  }
}

.mention-text {
  color: #1a73e8 !important;
}

.mention-dropdown-item {
  font-size: 11px !important;
  font-weight: normal !important;
  color: #091e42;
}

.tribute-container li.highlight {
  background: rgba(0, 0, 0, 0.04) !important;
}

.tribute-container {
  border-radius: 2px !important;
  max-width: 250px !important;
  overflow-x: hidden !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);

  li {
    font-size: 11px !important;
    background-color: #fff;
    line-height: 14px !important;
  }
}

.ui.grid > .row > [class*="wide ng-star-inserted eight"].column {
  width: 50%;
}

.abstract-export-modal {
  .mdc-dialog__container {
    padding: 0;
    overflow: hidden;
  }
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
  display: none;
}

bs-datepicker-container {
  z-index: 2001;
  .theme-dark-blue {
    left: 78px !important;
  }
}

.provision-review-schema-form {
  padding: 0 !important;

  fieldset {
    .widget.form-group {
      margin-bottom: 0 !important;

      .form-control {
        height: 24px;
        margin: 0;
        border-radius: 2px !important;
        border: solid 1px #dfe1e6;
        background-color: #f5f6f7;
        width: 100%;
        font-size: 12px !important;
        min-height: 24px !important;
        padding: 2px;
      }
    }
  }

  .ng-select {
    min-width: 114px !important;
    width: 100%;
    // max-width: 114px !important;

    .ng-select-container {
      color: #333 !important;
      background-color: #fff !important;
      border-radius: 2px !important;
      border: 1px solid #e0e0e0 !important;
      min-height: 24px !important;
      height: 24px !important;
      align-items: center !important;
      font-size: 12px !important;
    }

    .ng-arrow-wrapper {
      margin-top: 10px;
    }

    .ng-clear-wrapper {
      margin-top: 10px;
    }

    .ng-value-container {
      margin-top: 5px;
    }
  }

  .baseYear-formField {
    width: 100%;
  }
}

.itls-table {
  overflow: auto;
  position: absolute !important;
  bottom: 0px;
  right: 0px;
  width: calc(100vw - 315px);
  max-width: calc(100vw - 315px) !important;
  // min-width: calc(100vw - 600px) !important;
  overflow: visible !important;
  padding: 0 !important;
  box-shadow: none !important;
}
.itls-table-backdrop {
  background-color: transparent !important;
}

ng-dropdown-panel {
  z-index: 2000 !important;
}

itls-icon {
  &.grey-out {
    opacity: 0.3;
    cursor: auto;
  }

  &.disabled {
    opacity: 0.3;
    cursor: auto;
    pointer-events: none;
  }
}

grammarly-extension {
  display: none;
}

modal-container[role="dialog"].modal {
  overflow-y: hidden;
}

.activitiesMenu {
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mdc-checkbox__checkmark,
  .mdc-checkbox__background,
  .mat-mdc-checkbox-anim-unchecked-checked
    .mat-mdc-checkbox-anim-checked-unchecked {
    transform: scale(0.6);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-mdc-checkbox-layout {
    margin-bottom: 0;

    .mat-ripple {
      display: none;
    }
  }
}

.inteleaseContextMenu {
  box-shadow: 0 0 20px 0 #c9ced5;
  background-color: #ffffff;
  border-radius: 2px;
  padding-top: 8px;
  padding-bottom: 8px;

  .inteleaseContextMenuItem,
  [inteleaseContextMenuItem] {
    display: flex;
    padding: 0 16px;
    font-size: 12px !important;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    color: #091e42;

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
  }
}

//? change tosts messages style to show them top of backdrop
.cdk-overlay-container {
  div:has(.cdk-overlay-pane itls-pg-message-container) {
    z-index: 20000;
    position: absolute;
  }
}

.cdk-overlay-connected-position-bounding-box {
  .multi-select-dropdown {
    .mat-mdc-option,
    .mat-mdc-optgroup-label {
      display: flex;
      align-items: center;
      height: 35px;
    }
    .mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) {
      padding: 0;
      padding-left: 20px;
    }
  }
}
