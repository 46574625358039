@import "app.core";

@include core(); // from '@angular/material' thru 'app.core'

@mixin iconSize($size) {
  width: $size;
  height: $size;
  min-height: $size;
  min-width: $size;
  line-height: $size;
}

@mixin commonStyles() {
  a {
    text-decoration: none;
    outline: none;
    font-weight: $default-theme-link-font-weight;
  }

  label {
    font-size: $default-theme-label-font-size;
    font-weight: $default-theme-label-font-weight;
  }

  .mat-mdc-option {
    font-size: 12px;
  }

  .mat-mdc-tab-list {
    font-size: 12px;
  }

  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: none;
    border-width: 1px;
    border-style: solid;
    margin-bottom: -1px;
  }

  .mat-mdc-list-base .mat-mdc-list-item,
  .mat-mdc-list-base .mat-mdc-list-option {
    height: 38px !important;
  }

  .mat-mdc-list-base .mat-mdc-list-option {
    font-size: 12px !important;
  }

  .mat-mdc-menu-item {
    font-size: 12px !important;
    height: 36px;
    line-height: 36px;
    min-height: unset;

    .mat-icon {
      width: 16px !important;
      height: 16px !important;
      min-height: 16px !important;
      max-height: 16px !important;
      font-size: 14px !important;
      margin-right: 6px !important;
      margin-top: -4px !important;
    }
  }

  //
  //mat-icon {
  //  @include iconSize(14px);
  //}

  .mat-mdc-select-panel .mat-mdc-optgroup-label,
  .mat-mdc-select-panel .mat-mdc-option {
    font-size: inherit;
    line-height: 2.5em;
    height: 2.5em;
  }

  .mdc-button.mat-mdc-button-base,
  .mat-mdc-stroked-button.mat-mdc-button-base {
    line-height: 17px;
    padding: 6px 13px 6px 12px;
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  .mat-mdc-menu-panel {
    min-height: 52px;
    border-radius: 2px;
    box-shadow: 0 0 20px 0 #c9ced5;
  }

  .mat-mdc-icon-button {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;

    .mat-mdc-button-touch-target {
      width: 30px;
      height: 30px;
    }
    //
    //.mat-mdc-button-base {
    //    width: 30px !important;
    //    height: 30px !important;
    //    padding: 0 !important;
    //}
    //
    //.mat-mdc-focus-indicator {
    //    width: 30px;
    //    height: 30px;
    //}
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 30px;
    height: 30px;
    padding: 0;
  }

  .mat-mdc-button.mat-mdc-button-base {
    height: unset;
  }

  .mat-datepicker-content .mat-calendar-next-button,
  .mat-datepicker-content .mat-calendar-previous-button {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .mat-tree-node {
    min-height: unset;
  }
}

/// Note:
/// With angular material 18 or Material3, they have introduced new APIs for defining typography, themes, pallette etc, but these are not compatible with our current setup.
/// But they still have allowed to use those older Material2 APIs with a prefix 'm2-', we can still use those using the prefix. variables should be accessed as $m2-old-name.

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------
// Force the input field font sizes to 16px
$typography: m2-define-legacy-typography-config(
  $font-family: "Roboto ,sans-serif",
  $input: m2-define-typography-level(14px, 1.1, 400),
);

$default-theme-primary-palette: m2-define-palette($intelease-dark-blue);
$default-theme-accent-palette: m2-define-palette($intelease-light-blue);
$default-theme-warn-palette: m2-define-palette($intelease-light-pink);

$theme: m2-define-light-theme(
  $default-theme-primary-palette,
  $default-theme-accent-palette,
  $default-theme-warn-palette
);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
  @include all-component-themes($theme);
  @include intelease-core-theme($theme);
  @include intelease-components-theme($theme);
  @include all-component-typographies($typography);
  @include commonStyles();

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $default-theme-text-color;
  }

  .mdc-checkbox__checkmark {
    border-color: $default-theme-border-color;
  }

  a {
    color: $default-theme-link-color;

    &:hover {
      color: map-get($intelease-dark-blue, 500);
    }
  }

  label {
    color: $default-theme-label-color;
  }

  p {
    color: $default-theme-text-icons-color;
  }

  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    border-color: #ebebeb;
  }

  .mat-mdc-menu-item {
    color: #091e42;
  }

  .mat-mdc-menu-panel {
    box-shadow: 0 0 20px 0 #c9ced5;
    background-color: #ffffff;
  }

  .mat-drawer-container {
    background-color: #fff;
  }

  .mat-drawer {
    background-color: rgb(245, 246, 247);
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Define the dark theme
// -----------------------------------------------------------------------------------------------------

// Force the input field font sizes to 16px
$dark-theme-typography: m2-define-legacy-typography-config(
  $font-family: "Roboto ,sans-serif",
  $input: m2-define-typography-level(14px, 1.1, 400),
);

$dark-theme-primary-palette: m2-define-palette($intelease-black);
$dark-theme-accent-palette: m2-define-palette($intelease-white);
$dark-theme-warn-palette: m2-define-palette($m2-red-palette);

$dark-theme: m2-define-dark-theme(
  $dark-theme-primary-palette,
  $dark-theme-accent-palette,
  $dark-theme-warn-palette
);

// Add ".theme-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-dark {
  @include all-component-themes($dark-theme);
  @include intelease-core-theme($dark-theme);
  @include intelease-components-theme($dark-theme);
  @include all-component-typographies($dark-theme-typography);
  @include commonStyles();

  .mat-mdc-menu-panel {
    box-shadow: 0 0 20px 0 map-get($intelease-black, 500);
  }
}
