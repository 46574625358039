.individual-abstract-main {
  .nav-tabs > li > a {
    padding: 0 !important;

    span {
      padding: 13px 20px !important;
      display: inline-block !important;
      outline: none !important;
    }
  }
}

.individual-abstract-details-outer {
  .form-group {
    margin-bottom: 5px !important;
  }

  .card {
    input.form-control {
      font-size: 11px !important;
      height: 30px !important;
      min-height: 30px !important;
    }

    .form-group {
      margin-bottom: 5px !important;
    }

    span.formHelp {
      font-size: 12px !important;
    }

    margin-bottom: 10px !important;

    .card-body {
      padding: 5px !important;
    }
  }

  .individual-abstract-page-provision-item-card,
  .individual-abstract-page-provision-option-card {
    //border-radius: 8px !important;
    border: none !important;
    //&:hover {
    //  box-shadow: 0 0.625rem 3.5rem 0 rgba(198, 203, 222, 0.45) !important;
    //}
    //.backdrop {
    //  &:hover {
    //    opacity: 1 !important;
    //  }
    //}
  }

  .individual-abstract-page-provision-group-card {
    border-radius: 8px !important;
    box-shadow: unset !important;

    .card-header {
      padding: 10px 15px 5px 5px !important;
      min-height: 30px !important;
      height: 30px !important;

      .card-title {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.19;
        margin-left: 8px;
        letter-spacing: normal;
        text-align: left;
        color: #344563;
      }
    }
  }

  .individual-abstract-page-provision-item-card {
    margin-bottom: 0 !important;

    .card-header {
      min-height: 20px !important;
      height: 20px !important;
    }
  }

  .individual-abstract-page-provision-option-card {
    border-radius: 0 !important;

    &:hover {
      box-shadow: unset !important;
    }

    margin-bottom: 0 !important;
    padding: 0 !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .card-header {
      padding: 0 15px 0 5px !important;
      min-height: 20px !important;
      margin-top: 8px !important;

      .card-title {
        margin-left: 0 !important;
      }
    }

    .card-body {
      padding: 0 5px !important;
    }
  }

  .panel.card.panel-default {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 8px;

    .panel-heading {
      padding: 15px 20px 15px 20px !important;
    }
  }

  .accordion-title {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    margin-left: 8px;
    letter-spacing: normal;
    text-align: left;
    color: #7a8994;
  }
}

.individual-abstract-main {
  .nav-tabs .nav-link.active {
    border-bottom-color: #f5f6fa !important;
    background-color: transparent !important;
  }

  .suite-formField {
    display: inline-block;
    width: 63%;
  }

  .country-formField {
    display: inline-block;
    width: 34%;
    margin-left: 3%;
  }

  .city-formField {
    width: 35%;
    display: inline-block;
    margin-left: 2%;
  }

  .postalCode-formField {
    width: 30%;
    display: inline-block;
    margin-left: 0;
  }

  .state-formField {
    width: 30%;
    display: inline-block;
    margin-left: 3%;
  }

  .ng-select .ng-select-container {
    height: 30px !important;
    min-height: 30px !important;
  }

  .money-value-formField,
  .double-value-formField {
    width: 76%;
    display: inline-block;
  }

  .currency-formField,
  .unit-formField,
  .domain-formField {
    width: 20%;
    display: inline-block;
    margin-left: 2%;
    position: absolute;
  }
}

.individual-abstract-tasks-container {
  margin-top: 2px !important;

  .task-list-table {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    margin-left: 0 !important;
  }
}
