@mixin navbar-theme($theme) {
  $is-dark: map-get($theme, is-dark);

  navbar-vertical-style-2 {
  }

  .upload-button-container {
    @if ($is-dark) {
      background-color: map-get($intelease-black, 500);
    } @else {
      background-color: map-get($intelease-dark-blue, 500);
      color: map-get($intelease-white, 500);
      &:hover {
        background-color: map-get($intelease-dark-blue, 500) !important;
      }
    }
  }
}
