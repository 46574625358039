@mixin navigation-theme($theme) {
  $is-dark: map-get($theme, is-dark);

  @if ($is-dark) {
  } @else {
    .nav-link-title {
      color: $default-theme-secondary-text-color;
    }

    .nav-link {
      &.active {
        background-color: rgba(0, 0, 0, 0.04) !important;

        .nav-link-title,
        mat-icon {
          color: $default-theme-secondary-text-color !important;
        }
      }
    }

    .nav .nav-item.nav-collapsable > .children > .nav-item > .nav-link {
      color: #505f79 !important;
    }
  }
}
