@media (min-width: 768px) {
  .calculator-modal {
    width: 636px !important;
  }
}

@media (min-width: 768px) {
  .calculator-modal {
    max-width: 636px !important;
  }
}

.calculator-modal {
  .modal-content {
    background: none;
    border: none !important;

    il-rent-schedule-calculator-modal,
    il-term-calculator-modal {
      background-color: white;
    }
  }

  .bs-datepicker {
    background-color: #f5f6fa !important;
    box-shadow: none !important;
  }

  .bs-datepicker-body {
    border: none !important;
  }

  .bs-datepicker-head {
    background-color: unset;

    * {
      color: #344563;
    }
  }

  .bs-datepicker-body table td {
    color: #344563 !important;
    text-align: center !important;
    position: relative !important;
    padding: 0 !important;
    font-weight: bold !important;
  }

  .theme-green .bs-datepicker-body table td span.selected,
  .theme-green .bs-datepicker-body table td.selected span,
  .theme-green .bs-datepicker-body table td span[class*="select-"]:after,
  .theme-green .bs-datepicker-body table td[class*="select-"] span:after {
    background-color: #22ded7 !important;
  }

  .theme-green .bs-datepicker-body table td.week span {
    color: #22ded7 !important;
  }

  .table th,
  .table td {
    border: unset !important;
  }

  .table-container {
    .form-control {
      border-radius: 4px !important;
      border: solid 1px rgba(165, 173, 186, 0.62) !important;
      min-height: 40px !important;
    }

    th {
      padding: 13px 16px 0 16px !important;
      color: #a5adba !important;
      font-size: 10px !important;
      font-weight: bold !important;
    }
  }
}
